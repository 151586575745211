<template>
    <b-modal id="modal" :title="$t('projects.attachments')" no-close-on-backdrop
             no-close-on-esc
             size="lg"
             @close="onReset()"
    >
        <b-form v-if="data.id" id="form1" autocomplete="off" @reset="onReset" @submit.prevent="onSubmit">
            <b-row>
    
                <div class="form-group col-12">
                    <b-alert variant="info" show>
                        <span>{{$t('projects.infoAboutUploadFile')}}</span><br>
                        <span v-html="$t('projects.maxFileSize')"></span><br>
                        <span>{{$t('projects.fileCompress')}}</span>
                    </b-alert>
                    <local-file-manager
                        :read-only="((data.edit_locked)? true : false)"
                        :id-foreign-table-row="data.id"
                    ></local-file-manager>
                </div>
   
                <div class="col-12"></div>
            
            </b-row>
        </b-form>
        <template v-slot:modal-footer>
            <div class="w-100">
                <b-button type="reset" class="float-left" form="form1" variant="outline-secondary">
                    {{ $t('universalTxt.closeBtn') }}
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>

import LocalFileManager from "@/components/local-file-manager";
export default {
    name: "projects-attachments",
    components: {LocalFileManager},
    data() {
        return {
            id: parseInt(this.$route.params.id),
            data: {},
        }
    },
    methods: {
        
        async loadData() {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/list.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    params: {'id': this.id},
                    method: 'GET'
                });
                
                this.data = response.data[0];
                
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        async onSubmit() {
            try {
                await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/update-item.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: this.data,
                    method: 'PUT'
                });
                
                this.$emit('loadData');
                this.$bvModal.hide('modal');
                this.$router.go(-1);
                this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.success'));
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        async deleteItem(id) {
            try {
                if (confirm(this.$t('projects.deleteProject'))) {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/articles/remove-item.php',
                        headers: {'content-type': 'application/x-www-form-urlencoded'},
                        data: {'id': id},
                        method: 'DELETE'
                    });
                    this.$emit('loadData');
                    this.$bvModal.hide('modal');
                    this.$router.go(-1);
                    this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.deleted'));
                }
                
            } catch (error) {
                this.loadData();
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        onReset() {
            this.$emit('loadData');
            this.$router.go(-1);
        }
        
    },
    computed: {
        languages() {
            return this.$store.getters.langs
        },
    },
    mounted() {
        this.$bvModal.show('modal');
        this.loadData();
    },
    created() {
    
    }
}
</script>

<style lang="scss" scoped>

</style>
